(function ($) {
    // remove class by prefix
    $.fn.removePrefixedClasses = function (prefix) {
        var classes = $(this).attr("class").split(" ").filter(function(c) {
            return c.lastIndexOf(prefix, 0) !== 0;
        });
        $(this).attr("class", classes.join(" "));
    };

    // rating function
    $.fn.wpRating = function (numOfOptions, halfs){
        var $thisRating = $(this);
        var listOfOption = '<ul>'
        for (i = 0; i <= numOfOptions; i++){
            if (i != 0) listOfOption += '<li class="s-'+ i +'"></li>';
            if (halfs == true && numOfOptions != i) listOfOption += '<li class="s-'+ i +'-5"></li>';
        }
        listOfOption += '</ul>'
        $thisRating.append(listOfOption);
        $(this).hover(function(){
            $('ul li', this).hover(function(){
                if ($thisRating.hasClass($thisRating.attr('data-rating'))) $thisRating.removeClass($thisRating.attr('data-rating'));
                if ($thisRating.hasClass($thisRating.attr('data-rating-chosen'))) $thisRating.removeClass($thisRating.attr('data-rating-chosen'));
                $nextRating = $(this).attr('class');
                $thisRating.attr('data-rating',$nextRating);
                $thisRating.addClass($nextRating);
            }).click(function(){
                $nextRating = $(this).attr('class');
                $thisRating.attr('data-rating-chosen',$nextRating);

                var control = $(this).parents('.visitor.rating');
                var rating = $nextRating.replace('s-','');
                $.ajax({
                    url: (typeof atom_config !== 'undefined') ? atom_config.blog_url :  post_ratings.blog_url,
                    type: 'GET',
                    dataType: 'json',
                    context: this,
                    data: ({
                        post_id: control.data('post'),
                        rate: rating
                    }),

                    beforeSend: function(){
                     //   control.removeClass('error').addClass('loading');
                    },

                    error: function(){
                     //   control.addClass('error');
                    },

                    success: function(response){

                        $thisRating.attr('data-rating-chosen',$nextRating);
                    }
                });

            });
        }, function(){
            $(this).removePrefixedClasses('s-');
            $(this).addClass($(this).attr('data-rating-chosen'));
        });
    }

}(jQuery));


//
//
//jQuery(document).ready(function($){
//
//  // @todo: use $.on in future versions (needs jQuery 1.7+)
//  $(document).delegate('.ratings:not(.rated) a', 'click', function(event){
//
//    event.preventDefault();
//
//    var control = $(this).parents('.visitor.rating');
//
//    $.ajax({
//      url: (typeof atom_config !== 'undefined') ? atom_config.blog_url :  post_ratings.blog_url,
//      type: 'GET',
//      dataType: 'json',
//      context: this,
//
//      data: ({
//        post_id: control.data('post'),
//        rate: $(this).parents('li').index()
//      }),
//
//      beforeSend: function(){
//        control.removeClass('error').addClass('loading');
//      },
//
//      error: function(){
//        control.addClass('error');
//      },
//
//      success: function(response){
//
//        // we have an error, display it
//        if(response.error){
//          control.addClass('error').find('.meta').html(response.error);
//          $('a', control).remove();
//          return;
//        }
//
//        // no error, replace the control html with the new one
//        control.replaceWith($(response.html));
//
//        // other plugins can hook into this event.
//        // (the response object contains more info than we used here)
//        control.trigger('rated_post', response);
//      }
//    });
//
//    return true;
//
//  });
//
//});
